/* Variables */
$navbarCollapsedWidth: 52px;

.navbar {
  &.navbar-appearance-collapsed {
    width: $navbarCollapsedWidth;
    min-width: $navbarCollapsedWidth;
    max-width: $navbarCollapsedWidth;

    // Workspace
    .workspace-wrapper {
      padding: 0 0.5rem !important;
      display: flex !important;
      justify-content: center !important;

      & cpt-svg-icon {
        display: none !important;
      }
    }

    .workspace-image {
      margin: 0 !important;
      padding: 0 !important;
    }

    .workspace {
      padding: 0 !important;
      justify-content: center;
    }

    .workspace-label {
      display: none;
    }

    // Items
    .navbar-items {
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;

      & cpt-svg-icon {
        font-size: 1.25rem !important;
      }
    }

    .navbar-item {
      justify-content: center;
    }

    .navbar-item-label {
      display: none;
    }

    .credits {
      display: none;
    }
  }
}
