.zendesk-article {
  p {
    @apply text-b1 my-md;
  }

  h1 {
    @apply text-h1 my-md;
  }

  h2 {
    @apply text-h2 my-md;
  }
  h3 {
    @apply text-h3 my-md;
  }

  h4 {
    @apply text-h4 my-md;
  }

  ul {
    @apply pl-xl3 list-disc;
  }

  li {
    @apply list-disc text-b1;
  }
}
