/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import './ag-grid-captain-theme.scss';
@import 'assets/styles/navbar/index.scss';
@import 'assets/styles/misc/index.scss';

html,
body {
  @apply text-grey-900 overflow-hidden;
}

i svg {
  width: 100% !important;
  height: 100% !important;
}
