/* Variables */
$navbarExpandedWidth: 280px;

.navbar {
  position: sticky;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  top: 0;
  width: $navbarExpandedWidth;
  min-width: $navbarExpandedWidth;
  max-width: $navbarExpandedWidth;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  z-index: 200;
}

.navbar-dropdown {
  width: $navbarExpandedWidth;
  min-width: $navbarExpandedWidth;
  max-width: $navbarExpandedWidth;
}
